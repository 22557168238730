<template>
	<v-container fluid fill-height>
		<v-layout align-center justify-center>
			<v-flex xs12 sm8 md6>
				<material-card color="info" title="Политика Конфиденциальности приложения Atoto">
					<v-card>
						<v-card-text id="policy">
Политика конфиденциальности персональной информации (далее — Политика) действует в отношении всей
информации, которую приложение может получить о Пользователе во время использования. Использование приложения означает безоговорочное согласие Пользователя с
настоящей Политикой и указанными в ней условиями обработки его персональной информации.
<br><br>
<ol>
<li> Персональная информация Пользователей, которую обрабатывает Atoto</li>
<ol><li> В рамках настоящей Политики под «персональной информацией Пользователя» понимаются:</li>
<ol><li> Персональная информация, которую Пользователь предоставляет о себе самостоятельно при регистрации</li>
(создании учетной записи), включая персональные
данные Пользователя.
<li> Данные, которые автоматически передаются приложению и сайту в процессе их использования с
помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, технические характеристики оборудования и программного обеспечения, используемых Пользователем, дата и время доступа к Сервисам, адреса запрашиваемых страниц и иная подобная информация.</li>
</ol>
<li> Администрация Atoto не проверяет достоверность персональной информации, предоставляемой Пользователем, и
не имеет возможности оценивать ее достоверность. Однако, администрация исходит из того, что пользователь
предоставляет достоверную и достаточную персональную информацию и поддерживает эту информацию в
актуальном состоянии.</li>
</ol>
<li> Цели обработки персональной информации Пользователей</li>
<ol><li> Сервера Atoto хранят только ту персональную информацию, которая необходима для
предоставления Сервисов или исполнения соглашений и договоров с Пользователем,
за исключением случаев, когда законодательством предусмотрено обязательное хранение персональной
информации в течение определенного законом срока.</li>
<li>Персональную информацию Пользователя приложение обрабатывает в следующих целях:</li>
<ol><li>Идентификация.</li>
<li>Связь с Пользователем, в том числе направление уведомлений, запросов и информации, исполнения соглашений и договоров, а также обработка запросов и
заявок от Пользователя;</li>
<li>Улучшение качества работы приложения и сайта, удобства их использования, разработка новых
возможностей.</li>
<li>Проведение статистических и иных исследований на основе обезличенных данных.</li>
</ol></ol>
<li>Условия обработки персональной информации Пользователей и её передачи третьим лицам</li>
<ol>
<li>В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме случаев
добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу
лиц.</li>
<li>Atoto не передает персональную информацию Пользователя третьим лицам.</li>
<ol><li>Приложение обрабатывает персональные данные Пользователя путем ее обезличивания и использует полученные данные для сбора общей статистики посещения портала.</li>
</ol><li>При обработке персональных данных Пользователей приложение Atoto руководствуется Федеральным законом
РФ «О персональных данных».</li>
</ol><li>Изменение и удаление персональной информации. Обязательное хранение данных</li>
<ol><li>Пользователь может в любой момент изменить (обновить, дополнить) предоставленную им персональную
информацию или её часть, воспользовавшись функцией редактирования персональных данных.</li>
<li>Пользователь также может удалить предоставленную им в рамках определенной учетной записи
персональную информацию: <a href="https://atoto.ru/help/del">Инструкция</a>.</li>
</ol><li> Обработка персональной информации при помощи файлов Cookie и счетчиков</li>
<ol>
<li>Счетчики, размещенные в приложении или сайте, могут использоваться для сбора и обработки статистической информации об использовании
приложения, а также для обеспечения его работоспособности в целом или их отдельных
функций в частности.</li>
</ol>
<li> Меры, применяемые для защиты персональной информации Пользователя</li>
<ol><li> Администрация Atoto принимает необходимые и достаточные организационные и технические меры для защиты
персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения,
блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.</li>
</ol><li> Изменение Политики конфиденциальности. Применимое законодательство</li>
<ol><li> Администрация Atoto имеет право вносить изменения в настоящую Политику конфиденциальности. При внесении
изменений в актуальной редакции указывается дата последнего обновления. Новая редакция Политики вступает
в силу с момента ее размещения, если иное не предусмотрено новой редакцией Политики.</li>
<li> К настоящей Политике и отношениям между Пользователем и администрацией Atoto, возникающим в связи с
применением Политики конфиденциальности, подлежит применению право Российской Федерации.</li>
</ol><li> Обратная связь. Вопросы и предложения</li>
<li> Все предложения или вопросы по поводу настоящей Политики Пользователь вправе направлять на
электронную почту: <a href="mailto:info@atoto.ru">info@atoto.ru</a></li>
</ol>
<br>
<b>15.05.2023</b>
						</v-card-text>
					</v-card>
				</material-card>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>

export default {
	data: () => ({
	}),
	created() {
		document.title = "Политика Конфиденциальности приложения Atoto";
	},
};
</script>
<style scoped>
	#policy ol {
			list-style: none;
			counter-reset: li;
	}
	#policy li:before {
			counter-increment: li;
			content: counters(li,".") ". ";
			color: red;
			margin-left: -2em;
	}
</style>
